<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">Feedback</h3>

    <h5 class="font-bold text-neutral-800 mt-3">Anonymous voting</h5>
    <p class="text-neutral-800 mt-1">
      Control who can vote on posts. You can allow anyone to vote or require
      users to log in.
    </p>

    <div class="toggle-wrapper mt-2">
      <div class="button--toggle">
        <input
          type="checkbox"
          class="checkbox"
          @change="changeAllowVote()"
          v-model="allow_anonymous_vote"
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span
          v-if="!allow_anonymous_vote"
          key="login-required"
          class="flex items-center"
        >
          <span><strong>Off</strong> - Login required to vote</span>
        </span>
        <span v-else key="anyone-can-vote" class="flex items-center">
          <span><strong>On</strong> - Anyone can vote</span>
        </span>
      </transition>
    </div>

    <h5 class="font-bold text-neutral-800 mt-6">Anonymous posts</h5>
    <p class="text-neutral-800 mt-1">
      Control who can submit posts. You can allow anyone to post or require
      users to log in.
    </p>

    <div class="toggle-wrapper mt-2">
      <div class="button--toggle">
        <input
          type="checkbox"
          class="checkbox"
          @change="changeAllowAnonymous()"
          v-model="allow_anonymous_posts"
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span
          v-if="!allow_anonymous_posts"
          key="login-required"
          class="flex items-center"
        >
          <span><strong>Off</strong> - Login required to post</span>
        </span>
        <span v-else key="anyone-can-post" class="flex items-center">
          <span><strong>On</strong> - Anyone can post</span>
        </span>
      </transition>
    </div>

    <h5 class="font-bold text-neutral-800 mt-6">Reactions</h5>
    <p class="text-neutral-800 mt-1">
      Allow users to react to feedback posts and changelog updates with emoji.
    </p>

    <div class="toggle-wrapper mt-2">
      <div class="button--toggle">
        <input
          type="checkbox"
          class="checkbox"
          @change="changeAllowReactions()"
          v-model="allow_reactions"
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span
          v-if="allow_reactions"
          key="reactions-enabled"
          class="flex items-center"
        >
          <span><strong>On</strong> - Enable reactions</span>
        </span>
        <span v-else key="reactions-disabled" class="flex items-center">
          <span><strong>Off</strong> - Disable reactions</span>
        </span>
      </transition>
    </div>

    <div v-if="allow_reactions" class="mt-4">
      <h5 class="font-bold text-neutral-800 mt-6">Anonymous reactions</h5>
      <p class="text-neutral-800 mt-1">
        Allow users to react to feedback posts and changelog updates with emoji without logging in.
      </p>
    </div>

    <div v-if="allow_reactions" class="toggle-wrapper mt-4">
      <div class="button--toggle">
        <input
          type="checkbox"
          class="checkbox"
          @change="changeAllowAnonymousReactions()"
          v-model="allow_anonymous_reactions"
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span
          v-if="allow_anonymous_reactions"
          key="anonymous-reactions-allowed"
          class="flex items-center"
        >
          <span><strong>On</strong> - Anyone can react</span>
        </span>
        <span
          v-else
          key="login-required-for-reactions"
          class="flex items-center"
        >
          <span><strong>Off</strong> - Login required to react</span>
        </span>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    allowAnonymousVote: String,
    allowAnonymousPosts: String,
    allowReactions: String,
    allowAnonymousReactions: String,
    projectId: String,
    token: String,
  },
  data: function () {
    return {
      allow_anonymous_vote: JSON.parse(this.allowAnonymousVote),
      allow_anonymous_posts: JSON.parse(this.allowAnonymousPosts),
      allow_reactions: JSON.parse(this.allowReactions),
      allow_anonymous_reactions: JSON.parse(this.allowAnonymousReactions),
      project_id: this.projectId,
      form_token: this.token,
    };
  },
  methods: {
    changeAllowVote() {
      this.isAllowAnonymousToggleLoading = true;
      let data = new FormData();
      data.append("allow_anonymously_vote", this.allow_anonymous_vote);
      data.append("id", this.project_id);
      Rails.ajax({
        url: "/allow_anonymous_vote",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isAllowAnonymousToggleLoading = false;
          this.$emit(
            "allowAnonymouslyVote",
            data.allow_anonymously_vote.toString()
          );
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowAnonymousToggleLoading = false;
        },
      });
    },

    changeAllowAnonymous() {
      this.isAllowAnonymousToggleLoading = true;
      let data = new FormData();
      data.append("allow_anonymous_posts", this.allow_anonymous_posts);
      data.append("id", this.project_id);
      Rails.ajax({
        url: "/allow_anonymous_posts",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isAllowAnonymousToggleLoading = false;
          this.$emit(
            "allowAnonymousPosts",
            data.allow_anonymous_posts.toString()
          );
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowAnonymousToggleLoading = false;
        },
      });
    },

    changeAllowReactions() {
      this.isAllowReactionsToggleLoading = true;
      let data = new FormData();
      data.append("allow_reactions", this.allow_reactions);
      Rails.ajax({
        url: "/allow_reactions",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isAllowReactionsToggleLoading = false;
          this.$emit("allowReactions", data.allow_reactions.toString());
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowReactionsToggleLoading = false;
        },
      });
    },

    changeAllowAnonymousReactions() {
      this.isAllowAnonymousReactionsToggleLoading = true;
      let data = new FormData();
      data.append("allow_anonymous_reactions", this.allow_anonymous_reactions);
      Rails.ajax({
        url: "/allow_anonymous_reactions",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isAllowAnonymousReactionsToggleLoading = false;
          this.$emit(
            "allowAnonymousReactions",
            data.allow_anonymous_reactions.toString()
          );
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowAnonymousReactionsToggleLoading = false;
        },
      });
    },
  },
};
</script>
<style scoped>
input[type="checkbox"] {
  min-width: 5mm;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 5mm;
  border: 0.1mm solid #00000052;
  border-radius: 5px;
  margin-top: 3px;
}

input[type="checkbox"]:checked:after {
  margin-left: 1.5mm;
  width: 1.5mm;
  margin-bottom: 1mm;
  height: 4mm;
  border: solid green;
  border-width: 0 1mm 1mm 0;
  transform: rotate(45deg);
  content: "";
  display: inline-block;
}
</style>

<style scoped lang="scss">
.area--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
}

.button--toggle {
  position: relative;
  width: 74px;
  height: 36px;
  overflow: hidden;
  margin-right: 0.5rem;
}

.button--toggle,
.button--toggle .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f5f5f5;
  transition: 0.3s ease all;
  z-index: 1;
  border: 1px solid gainsboro;
}

.button--toggle .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background-color: #d2d2d2;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.button--toggle .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

.button--toggle .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

.button--toggle .checkbox:checked + .knobs:before {
  content: "";
  left: 42px;
  background-color: #3bd087;
  @media only screen and (min-width: 381px) and (max-width: 420px) {
    left: 40px;
  }
  @media only screen and (min-width: 320px) and (max-width: 350px) {
    left: 25px;
  }
  @media only screen and (min-width: 351px) and (max-width: 380px) {
    left: 32px;
  }
}

.button--toggle .checkbox:checked ~ .layer {
  background-color: #d1f0e1;
  border-color: #a3e6c6;
}

.ai-screenshot-wrapper {
  height: 95px;
  margin-top: 0.5rem;
}

.ai-screenshot {
  max-width: 100%;
  display: block;
  border-radius: 4px;
}

/* Animations */

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.fadeIn-enter-to,
.fadeIn-leave {
  opacity: 1;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 200ms ease-in;
}

.fromBottom-move {
  transition: all 250ms;
}
.fromBottom-enter-active,
.fromBottom-leave-active {
  transition: all 250ms;
  position: absolute;
}
.fromBottom-enter,
.fromBottom-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.fromLeft-move {
  transition: all 250ms;
}
.fromLeft-enter-active,
.fromLeft-leave-active {
  transition: all 250ms;
}
.fromLeft-enter,
.fromLeft-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
