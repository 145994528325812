<template>
  <div class="flex items-start px-4">
    <a
      @click.prevent="castVote()"
      href="#"
      class="text-neutral-600 text-lg flex flex-col items-center justify-center p-2 mr-4 border hover:no-underline hover:bg-dblue-100 hover:bg-opacity-50 rounded-lg transition duration-200 ease-in-out"
      :class="{ 'text-project-500': alreadyVoted }"
    >
      <svg
        class="w-8 h-8"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div
        id="post-upvotes"
        class="font-bold leading-none text-lg -mt-1 mb-1"
        v-if="!allowVoteCount || currentProjectAdmin"
      >
        {{ upvotes }}
      </div>
    </a>

    <div
      class="flex flex-col items-start justify-start"
      id="board-post-data"
    >
      <h3 class="text-lg font-semibold" v-line-clamp="lines">
        <a
          @click.prevent="toIdeaPage"
          :href="post.url"
          class="text-neutral-900 hover:no-underline hover:text-project-500 transition duration-200 ease-in-out force-break-words"
          id="post-title"
          >{{ post.title }}</a
        >
      </h3>
      <div class="flex items-baseline space-x-1">
        <post-status
          :status="post.status.name"
          :color="post.status.color"
          :status-name="statusName"
        ></post-status>
        <div class="" v-if="currentProjectAdmin && post.is_private">
          <span
            class="text-xs text-neutral-700 rounded-full px-2 py-1 bg-gray-100 uppercase font-bold tracking-wider"
            >{{ $t("Private") }}</span
          >
        </div>
        <div v-if="post.is_spam && currentProjectAdmin">
          <span
            class="text-xs text-red-700 rounded-full px-2 py-1 bg-red-100 uppercase font-bold tracking-wider flex items-center"
          >
            {{ $t("Marked as Spam") }}
          </span>
        </div>
      </div>
      <p
        class="break-words text-neutral-800 mt-1 force-break-words"
        v-if="post.content"
        v-line-clamp="lines"
      >
        {{ content() }}
      </p>
      <div
        class="flex flex-wrap text-sm text-neutral-700 mt-1"
        id="board-post-comment"
      >
        <span class="mr-2" v-if="showBoard" id="post-board">
          {{ post.board.name }}
        </span>
        <span class="mr-3 flex items-center">
          <simple-svg
            :filepath="commentsIcon"
            width="16px"
            height="16px"
            class="mr-1"
          />
          {{ commentsCountHuman() }}
        </span>
        <span
          class="flex items-center"
          v-if="post.tags && post.tags.length > 0 && currentProjectAdmin"
        >
          <simple-svg
            :filepath="tagsIcon"
            width="16px"
            height="16px"
            class="mr-1"
          />
          {{ postTags }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import upvoteIcon from "images/upvote_chevron.svg";
import tagsIcon from "images/tags.svg";
import commentsIcon from "images/comments.svg";
import EventBus from "event_bus";
import Reactions from './reactions.vue';

export default {
  props: [
    "original-upvotes",
    "current-user",
    "original-post",
    "current-project-admin",
    "show-board",
    "allow-anonymous",
    "allow-vote-count",
    "status-name",
    "is-board",
  ],
  data: function () {
    return {
      post: this.originalPost,
      upvotes: this.originalPost.total_upvotes,
      lines: 5,
      upvoteIcon,
      tagsIcon,
      commentsIcon,
      ip_address: "",
    };
  },
  components: {
    upvoteIcon,
    tagsIcon,
    commentsIcon,
    Reactions
  },
  methods: {
    commentsCountHuman: function () {
      var message = "";

      if (this.post.comments_count === 0) {
        message = this.$t("No comments yet");
      } else if (this.post.comments_count === 1) {
        message = "1 comment";
      } else {
        message = this.post.comments_count + " comments";
      }

      return message;
    },
    content() {
      var text = this.post.content;
      text = this.post.content.replace(/[\u0022\u201C\u201D]/g, "");
      if (text.length < 2) {
        text = null;
      }
      return text;
    },
    castVote: function () {
      var vote_post_url =
        "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote";

      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip_address = ip;
        });

      let data = new FormData();
      data.append("ip_address", this.ip_address);

      if (this.allowAnonymous || this.currentUser) {
        if (!this.currentUser) {
          const lastVoteTime = localStorage.getItem('lastVoteTime_' + this.post.id);
          const currentTime = new Date().getTime();

          if (lastVoteTime && currentTime - lastVoteTime < 1000) {
            return;
          }

          localStorage.setItem('lastVoteTime_' + this.post.id, currentTime);
        }
        // Set the user has voted for state early for immediate feedback
        this.post.current_user_voted_for = !this.post.current_user_voted_for;

        Rails.ajax({
          url: vote_post_url,
          type: "POST",
          data: data,
          dataType: "json",
          beforeSend: function () {
            return true;
          },
          success: (data) => {
            if (data.msge == "annonymously") {
              // document.getElementById("annony").classList.remove("hidden");
            }

            if (data.status == 'unprocessable_entity') {
              console.log("Error voting:", data);
              return;
            }

            if (data.votes) {
              EventBus.$emit("postVotes", {
                id: this.post.id,
                total: data.votes,
              });

              this.upvotes = data.votes;
            }
          },
          error: (data, status) => {
            console.log("Error voting:", data);
          },
        });
      } else {
        EventBus.$emit("openRegModalSignup");
      }
    },
    toIdeaPage() {
      window.location.href = this.post.url;
    },
  },

  computed: {
    postTags: function () {
      return this.post.tags.map((tag) => tag.name).join(", ");
    },
    alreadyVoted() {
      return this.post.current_user_voted_for;
    },
  },
};
</script>

<style scoped></style>
